import React, { useContext, useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { Worker } from '../../../../app/models/Workers';
import { AuthContext } from '../../../../contexts/AuthContext';
import { FundsRequestDataForm, FundRequestReason } from '../../../../app/models/Fund';

interface Props {
    fundsRequest: FundsRequestDataForm;
    setFundsRequest: (expense: FundsRequestDataForm) => void;
    reasons: FundRequestReason[];
    workers: Worker[];
    errorFields?: any;
}

const FundsRequestForm = ({
    fundsRequest,
    setFundsRequest,
    workers,
    reasons,
    errorFields
}: Props) => {

    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const { auth } = useContext(AuthContext);

    const handleChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setFundsRequest({ ...fundsRequest, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: any) => {
        const obj = { [name]: value };
        setFundsRequest({ ...fundsRequest, ...obj });
    };

    const workerOptions = workers.map((worker) => ({
        id: worker.id,
        name: worker.user
            ? worker.user.first_name + ' ' + worker.user.last_name
            : 'Nombre no disponible'
    }));

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="mb-2 row needs-validation">
                        <label className="col-md-4 col-form-label">Motivo</label>
                        <div className="col-md-12">
                            <SelectTwo
                                name="reason"
                                id="reason"
                                inputValue={fundsRequest.request_reason}
                                options={SelectTwoMapperOptions(reasons)}
                                hasError={fieldHasError('reason') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('request_reason', value?.value)
                                }
                                placeholder={'Seleccione el motivo'}
                                onFocus={() => onFocusRemove('reason')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('reason')}
                            </div>
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-md-6">
                            <label className="col-md-6 col-form-label">Fecha de inicio</label>
                            <div className="col-md-12">
                                <input
                                    type="date"
                                    className={`form-control ${fieldHasError('start_date')}`}
                                    name="start_date"
                                    id="start_date"
                                    value={fundsRequest.start_date?.toString()}
                                    onChange={handleChange}
                                    placeholder="Ingrese una fecha"
                                    onFocus={() => onFocusRemove('date')}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('start_date')}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <label className="col-md-9 col-form-label">Fecha de termino</label>
                            <div className="col-md-12">
                                <input
                                    type="date"
                                    className={`form-control ${fieldHasError('end_date')}`}
                                    name="end_date"
                                    id="end_date"
                                    value={fundsRequest.end_date?.toString()}
                                    onChange={handleChange}
                                    placeholder="Ingrese una fecha"
                                    onFocus={() => onFocusRemove('end_date')}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('end_date')}
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="mb-2 row needs-validation">
                        <label className="col-md-4 col-form-label">Trabajador</label>
                        <div className="col-md-12">
                            <SelectTwo
                                name="worker_id"
                                id="worker_id"
                                inputValue={fundsRequest.worker_id}
                                options={SelectTwoMapperOptions(workerOptions)}
                                hasError={fieldHasError('worker_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('worker_id', value?.value)
                                }
                                placeholder={'Seleccione el trabajador'}
                                onFocus={() => onFocusRemove('worker_id')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('worker_id')}
                            </div>
                        </div>
                    </div>

                    <div className="mb-2 row needs-validation">
                        <label className="col-md-4 col-form-label">Total</label>
                        <div className="col-md-12">
                            <input
                                name="total"
                                id="total"
                                type="number"
                                className={`form-control ${fieldHasError('total')}`}
                                value={fundsRequest.total}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('total')}
                                placeholder="Ingrese monto total"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('workertotal_id')}
                            </div>
                        </div>
                    </div>
            

                </div>
            </div>
        </>
    );
};

export default FundsRequestForm;

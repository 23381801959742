import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';

import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import useSweetAlert from '../../hooks/useSweetAlert';
import { toast } from 'react-toastify';

import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';

import useNavigationPage from '../../hooks/useNavigationPage';
import FundsRequestCreate from '../WorkOrders/components/FundsRequestCreate';
import { FundRequestStatus, FundsFilters, FundsRequestDataForm } from '../../app/models/Fund';
import useFundsRequestService from '../../app/services/hooks/useFundsRequestService';
import FundsRequestDataTable from '../WorkOrders/components/tables/FundsRequestDataTable';
import FundsRequestEdit from '../WorkOrders/components/FundsRequestEdit';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'funds-requests',
        url: '/funds',
        isActive: true
    }
];

const Funds = () => {

    const { getAllFundsRequests, fetchingAllFundsRequest, deleteFundsRequest} = useFundsRequestService()
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [ showingCreate, setShowingCreate ] = useState(false);
    const [ showingEdit, setShowingEdit ] = useState(false);
    const [ fundsRequests, setFundsRequests] = useState<FundsRequestDataForm[]>([]);
    const [ fundRequestStatuses, setFundRequestStatuses ] = useState<FundRequestStatus[]>([])
    const [ totalRows, setTotalRows ] = useState<number>(0);
    const [ fundRequestId, setFundRequestId ] = useState<number>(0);
    const { navigationPage } = useNavigationPage();
    
    const [filter, setFilter] = useState<FundsFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [filter.page, filter.per_page, filter.sort, filter.order]);

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideRequest = () => {
        setShowingCreate(false);
    };
    
    const edit = (id: number) => {
        setFundRequestId(id)
        setShowingEdit(true)
    };

    const hideEdit = () => {
        setShowingEdit(false);
    };
    

    const reloadTable = () => { 
        getAllFundsRequests(filter, {
            onSuccess: (response: ServiceResponse) => {
                setFundsRequests(response.data.funds_requests);
                setTotalRows(response.data.total_rows);
                setFundRequestStatuses(response.data.statuses)
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });
            
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    }

    const destroy = (fundsRequestId: number) => {
        const _text = 'Está a punto de eliminar la solicitud de fondos #' + fundsRequestId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando solicitud de fondos...');
                        deleteFundsRequest(fundsRequestId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La orden de trabajo no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };
    

    return (
        <>
            <Breadcrumbs pageSection="Fondos" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <DefaultToolBar
                    left={<ButtonCreate callbackCreate={showCreate} title="Solicitar Fondos" />}
                />

                <FundsRequestDataTable 
                    fundsRequests={fundsRequests} 
                    totalRows={totalRows} 
                    statuses={fundRequestStatuses}
                    filter={filter} 
                    setFilter={setFilter}
                    loading={fetchingAllFundsRequest}
                    callbackSuccess={reloadTable}
                    onSearch={reloadTable}
                    destroy={destroy}
                    edit={edit}
                />

            </DefaultCard>

            {showingCreate && (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideRequest}
                    title="Añadir nueva solicitud"
                    backdrop={true}
                    showFooter={false}
                >
                    <FundsRequestCreate
                        onSaved={() => {
                            reloadTable();
                            hideRequest();
                        }}
                        onCancel={hideRequest}
                        onError={hideRequest}
                    />
                </DefaultModal>
            )}

            {showingEdit && (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Añadir nueva solicitud"
                    backdrop={true}
                    showFooter={false}
                >
                    <FundsRequestEdit
                        fundRequestId={fundRequestId}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            )}
        </>
    );

}

export default Funds;

import DefaultDataTable from '../../../../components/default/DefaultDataTable';

import { momentParseDate } from '../../../../helpers';

import { formatNumber } from '../../../../utils/utils';
import { Link } from 'react-router-dom';

import circleRed from '../../../../assets/images/icons/circle-color-red.svg';
import circleGreen from '../../../../assets/images/icons/circle-color-green.svg';
import circleGrey from '../../../../assets/images/icons/circle-color-grey.svg';
import { useScreenDetector } from '../../../../hooks/useScreenDetector';
import { FundsRequestDataForm, FundsFilters, FundRequestStatus } from '../../../../app/models/Fund';
import FundsRequestFilter from './FundsRequestFilter';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import FundRequestChangeStaFundRequest from '../FundRequestChangeStatus';



interface Props {
    fundsRequests: FundsRequestDataForm[];
    totalRows: number;
    statuses: FundRequestStatus[];
    loading?: boolean;
    callbackSuccess?: () => void;
    filter: FundsFilters;
    setFilter: (filter: FundsFilters) => void;
    onSearch: () => void;
    destroy?: (id: number) => void;
    edit?: (quoteId: number) => void;
}

const FundsRequestDataTable = ({
    fundsRequests,
    totalRows,
    statuses,
    loading = false,
    callbackSuccess,
    filter,
    setFilter,
    onSearch,
    destroy,
    edit
}: Props) => {

    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row?.id,
            sortable: true,
            sortField: 'id',
            cell: (row: FundsRequestDataForm, index: any, column: any) => (
                <span className="">
                    {/* <img
                        src={
                            row.is_approved == null
                                ? circleGrey
                                : !row.is_approved
                                ? circleRed
                                : circleGreen
                        }
                        alt="circle"
                        style={{
                            width: '25px',
                            height: '25px',
                            marginRight: '5px',
                            marginBottom: '3px'
                        }}
                    /> */}
                    <span className="">{row.id}</span>
                </span>
            )
        },
        {
            name: 'Conductor',
            selector: (row: any) => row?.worker_name,
            sortable: true,
            sortField: 'worker',
            cell: (row: FundsRequestDataForm, index: any, column: any) => (
                <span className="">{row.worker_name}</span>
            )
        },

        {
            name: 'Motivo',
            selector: (row: any) => row?.request_reason,
            sortable: true,
            sortField: 'request_reason',
            cell: (row: FundsRequestDataForm, index: any, column: any) => (
                <span className="">{row.request_reason?.toString()}</span>
            )
        },
        
        
        {
            name: 'Periodo Desde',
            selector: (row: any) => row?.start_date,
            sortable: true,
            sortField: 'start_date',
            cell: (row: FundsRequestDataForm, index: any, column: any) => (
                <span className="">{momentParseDate(row?.start_date)}</span>
            )
        },
        {
            name: 'Periodo Hasta',
            selector: (row: any) => row?.end_date,
            sortable: true,
            sortField: 'end_date',
            cell: (row: FundsRequestDataForm, index: any, column: any) => (
                <span className="">{momentParseDate(row?.end_date)}</span>
            )
        },

        {
            name: 'Total',
            selector: (row: any) => row?.total,
            sortable: true,
            sortField: 'total',
            cell: (row: FundsRequestDataForm, index: any, column: any) => (
                <>
                    <span className="">
                        {formatNumber(row.total ? Number(row.total) : 0)}
                    </span>
                </>
            )
        },
        {
            name: 'Solicitado por',
            selector: (row: any) => row?.created_user,
            sortable: true,
            sortField: 'created_user',
            cell: (row: FundsRequestDataForm, index: any, column: any) => (
                    <span className="">{row.created_user}</span>
            )
        },
        {
            name: 'Estado',
            selector: (row: any) => row.status,
            sortable: true,
            sortField: 'status',
            cell: (row: FundsRequestDataForm, index: any, column: any) => {
                const status = statuses.find((fRStatus) => fRStatus.id === row.status);
                if (statuses.length == 0) {
                    return '';
                }
                
                return (
                    <FundRequestChangeStaFundRequest
                        callbackSuccess={callbackSuccess}
                        statuses={row.next_status_options!}
                        selectedStatus={status!}
                        fundRequestId={row.id!}
                    />
                );
            }
        },
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            cell: (row: FundsRequestDataForm, index: any, column: any) => (
                <div className="">
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row?.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }

    ];


    const { isDesktop } = useScreenDetector();

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <FundsRequestFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
            </div>

            <DefaultDataTable
                title=" "
                columns={columns}
                data={fundsRequests}
                progressPending={loading}
                isMobileStacked={true}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
                selectableRowsHighlight={true}
            />
        </>
    );
};

export default FundsRequestDataTable;

import React, { useContext } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import { FundRequestStatus } from '../../../app/models/Fund';
import useFundsRequestService from '../../../app/services/hooks/useFundsRequestService';

interface Props {
    fundRequestId: number;
    statuses: FundRequestStatus[];
    selectedStatus: FundRequestStatus;
    callbackSuccess?: () => void;
}

const FundRequestChangeStaFundRequest = ({ fundRequestId, statuses, selectedStatus, callbackSuccess }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [selected, setSelected] = React.useState<FundRequestStatus>(selectedStatus);

    const { changeFundsRequestStatus } = useFundsRequestService();


    const eventChangeStatus = {
        onSuccess: (response: ServiceResponse) => {
            if (hideLoading) hideLoading();
            if (callbackSuccess) {
                callbackSuccess();
            }

            useReactConfirmAlert().successAlert({
                title: 'Éxito',
                message: 'Se ha cambiado el estado de la Oferta #' + fundRequestId
            });
        },
        onError: (response: ServiceResponse) => {
            if (hideLoading) hideLoading();
            useReactConfirmAlert().errorAlert({
                title: 'Error',
                message: response.message
            });
            toast.error(response.message);
            if (callbackSuccess) {
                callbackSuccess();
            }
        }
    };

    const changeStatus = (value: string) => {
        if (value == selected.id) return null;

        const _status = statuses.find((status: FundRequestStatus) => status.id == value);

        setSelected(_status!);

        const _text =
            'Está a punto de cambiar el estado de la Oferta #' +
            fundRequestId +
            ' a ' +
            (_status ? _status.name : '');

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, cambiar estado',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Cambiando estado...');
                        if (_status){ 
                            changeFundsRequestStatus(fundRequestId, _status, eventChangeStatus)
                        } else{
                            if (hideLoading) hideLoading();
                            setTimeout(() => {
                                useReactConfirmAlert().infoAlert({
                                    title: 'Cancelado',
                                    message: 'Opción no encontrada.'
                                });
                            }, 0);
                            setSelected(selectedStatus);
                        }
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El estado de la Oferta no se ha cambiado.'
                            });
                        }, 0);
                        setSelected(selectedStatus);
                    }
                }
            }
        });
    };

    if (!selectedStatus) return null;

    return (
        <select
            className="form-control"
            value={selected.id}
            onChange={(e) => changeStatus(e.target.value)}
        >
            <option value={selectedStatus.id}>{selectedStatus.name}</option>
            {statuses.map((status) => {
                if(status.id !== selected.id)
                return (
                    <option key={status.id} value={status.id}>
                        {status.name}
                    </option>
                );
            })}
        </select>
    );
};

export default FundRequestChangeStaFundRequest
import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { FundRequestStatus, FundsFilters, FundsRequestDataForm } from '../../models/Fund';

const useFundsRequestService = () => {

    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingStoreFundsRequest, setFetchingStoreFundsRequest] = useState(false);
    const [fetchingCreateFundsRequest, setFetchingCreateFundsRequest] = useState(false);
    const [fetchingCalculateFundsRequest, setFetchingCalculateFundsRequest] = useState(false);
    const [fetchingAllFundsRequest, setFetchingAllFundsRequest] = useState(false);
    const [fetchingDeleteFundsRequest, setFetchingDeleteFundsRequest] = useState(false);
    const [fetchingChangeFundsRequestStatus, setFetchingChangeFundsRequestStatus] = useState(false);
    const [fetchingEditFundsRequest, setFetchingEditFundsRequest] = useState(false);
    const [fetchingUpdateFundsRequest, setFetchingUpdateFundsRequest] = useState(false);

    const getAllFundsRequests = (
        filter: FundsFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.FUNDS_REQUEST.ALL}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingAllFundsRequest
        });
    };

    const storeFundsRequest = (fundRequest: FundsRequestDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.FUNDS_REQUEST.STORE_FUNDS_REQUEST,
            body: fundRequest,
            setFetching: setFetchingStoreFundsRequest
        });
    };

    const createFundsRequest = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.FUNDS_REQUEST.CREATE_FUNDS_REQUEST,
            setFetching: setFetchingCreateFundsRequest
        });
    };

    const calculateFundsRequest = (
        start_date: string | Date, 
        end_date: string | Date, 
        worker_id: number, 
        request_reason: string, 
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.FUNDS_REQUEST.CALCULATE_FUNDS_REQUEST.replace(':start_date', start_date.toString())
                .replace(':end_date', end_date.toString())
                .replace(':worker_id', worker_id.toString())
                .replace(':request_reason', request_reason),
            setFetching: setFetchingCalculateFundsRequest
        });
    };

    const deleteFundsRequest = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.FUNDS_REQUEST.DELETE_FUNDS_REQUEST.replace(':id', id.toString()),
            setFetching: setFetchingDeleteFundsRequest
        });
    };

    const changeFundsRequestStatus = (id: number, status: FundRequestStatus, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.FUNDS_REQUEST.CHANGE_FUNDS_REQUEST_STATUS.replace(':id', id.toString()),
            body: status,
            setFetching: setFetchingChangeFundsRequestStatus
        });
    };

        const editFundsRequest = (id: number, events: ServiceEvents = {}) => {
            doGet({
                ...events,
                url: EndPoints.FUNDS_REQUEST.EDIT_FUNDS_REQUEST.replace(':id', id.toString()),
                setFetching: setFetchingEditFundsRequest
            });
        };
    
        const updateFundsRequest = (
            id: number,
            fundsRequest: FundsRequestDataForm,
            events: ServiceEvents = {}
        ) => {
            doPut({
                ...events,
                url: EndPoints.FUNDS_REQUEST.UPDATE_FUNDS_REQUEST.replace(':id', id.toString()),
                body: fundsRequest,
                setFetching: setFetchingUpdateFundsRequest
            });
        };


    return {
        fetchingStoreFundsRequest,
        fetchingCreateFundsRequest,
        fetchingAllFundsRequest,
        fetchingDeleteFundsRequest,
        fetchingCalculateFundsRequest,
        fetchingChangeFundsRequestStatus,
        fetchingEditFundsRequest,
        fetchingUpdateFundsRequest,
        getAllFundsRequests,
        storeFundsRequest,
        createFundsRequest,
        deleteFundsRequest,
        calculateFundsRequest,
        changeFundsRequestStatus,
        editFundsRequest,
        updateFundsRequest
    };
};

export default useFundsRequestService;
import React, { useContext, useEffect, useState, useRef } from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import LazyLoading from '../../../components/LazyLoading';
import { Worker } from '../../../app/models/Workers';
import FundsRequestForm from './forms/FundsRequestForm';
import { defaultFundsRequestDataForm, FundsRequestDataForm, FundRequestReason } from '../../../app/models/Fund';
import useFundsRequestService from '../../../app/services/hooks/useFundsRequestService';

interface Props {
    fundRequestId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const FundsRequestEdit = ({
    fundRequestId,
    onSaved,
    onCancel,
    onError,
}: Props) => {
    const [ fundsRequest, setFundsRequest ] = useState<FundsRequestDataForm>({
        ...defaultFundsRequestDataForm
    });
    const [workers, setWorkers] = useState<Worker[]>([]);
    const [requestReasons, setRequestReasons] = useState<FundRequestReason[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    const selectedWorkerId = useRef<number | undefined>(0);
    const selectedStartDate = useRef<string | undefined>('');
    const selectedEndDate = useRef<string | undefined>('');
    const selectedRequestReason = useRef<string | undefined>('');

    const { updateFundsRequest, 
        editFundsRequest, 
        calculateFundsRequest, 
        fetchingCreateFundsRequest, 
    } = useFundsRequestService();

    const { showLoading, hideLoading } = useContext(AppContext);

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        editFundsRequest(fundRequestId, {
            onSuccess: (response: ServiceResponse) => {
                setFundsRequest(response.data.fund_request)
                setWorkers(response.data.worker);
                setRequestReasons(response.data.reason_choices);

                selectedWorkerId.current = response.data.fund_request.worker_id;
                selectedStartDate.current = response.data.fund_request.start_date;
                selectedEndDate.current = response.data.fund_request.end_date;
                selectedRequestReason.current = response.data.fund_request.request_reason;

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const update = () => {
        if (showLoading) showLoading('loading', 'Cargando solicitud...');
        updateFundsRequest(fundRequestId, fundsRequest, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    useEffect(() => {
        if(fundsRequest.worker_id && 
            fundsRequest.start_date && 
            fundsRequest.end_date && 
            fundsRequest.request_reason && (
                fundsRequest.worker_id != selectedWorkerId.current || 
                fundsRequest.start_date != selectedStartDate.current ||
                fundsRequest.end_date != selectedEndDate.current ||
                fundsRequest.request_reason != selectedRequestReason.current
            )){
            calculateFundsRequest(fundsRequest.start_date,
                fundsRequest.end_date, 
                fundsRequest.worker_id,
                fundsRequest.request_reason, {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();

                    selectedWorkerId.current = fundsRequest.worker_id;
                    selectedStartDate.current = fundsRequest.start_date.toString();
                    selectedEndDate.current = fundsRequest.end_date.toString();
                    selectedRequestReason.current = fundsRequest.request_reason;  
                 
                    setFundsRequest({
                        ...fundsRequest,
                        total: response.data.total_expenses
                    })
                },
                onError: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            });
        }
    }, [
        fundsRequest.worker_id,
        fundsRequest.start_date,
        fundsRequest.end_date,
        fundsRequest.request_reason
    ]);

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateFundsRequest ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <FundsRequestForm
                fundsRequest={fundsRequest}
                setFundsRequest={setFundsRequest}
                reasons={requestReasons}
                workers={workers}
                errorFields={errorFields}
            />
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={update} />
                </div>
            </div>
        </>
    );
};

export default FundsRequestEdit;
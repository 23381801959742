import React, { useContext, useEffect, useState } from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import LazyLoading from '../../../components/LazyLoading';
import { Worker } from '../../../app/models/Workers';
import FundsRequestForm from './forms/FundsRequestForm';
import { defaultFundsRequestDataForm, FundsRequestDataForm, FundRequestReason } from '../../../app/models/Fund';
import useFundsRequestService from '../../../app/services/hooks/useFundsRequestService';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const FundsRequestCreate = ({
    onSaved,
    onCancel,
    onError,
}: Props) => {
    const [ fundsRequest, setFundsRequest ] = useState<FundsRequestDataForm>({
        ...defaultFundsRequestDataForm
    });
    const [workers, setWorkers] = useState<Worker[]>([]);
    const [requestReasons, setRequestReasons] = useState<FundRequestReason[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    const { storeFundsRequest, 
        createFundsRequest, 
        calculateFundsRequest, 
        fetchingCreateFundsRequest, 
        fetchingStoreFundsRequest 
    } = useFundsRequestService()

    const { showLoading, hideLoading } = useContext(AppContext);

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createFundsRequest({
            onSuccess: (response: ServiceResponse) => {
                setWorkers(response.data.worker);
                setRequestReasons(response.data.reason_choices);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        if (showLoading) showLoading('loading', 'Cargando solicitud...');
        storeFundsRequest(fundsRequest, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    useEffect(() => {
        if(fundsRequest.worker_id && 
            fundsRequest.start_date && 
            fundsRequest.end_date && 
            fundsRequest.request_reason){
            calculateFundsRequest(fundsRequest.start_date,
                fundsRequest.end_date, 
                fundsRequest.worker_id,
                fundsRequest.request_reason, {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setFundsRequest({
                        ...fundsRequest,
                        total: response.data.total_expenses
                    })
                },
                onError: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            });
        }
    }, [
        fundsRequest.worker_id,
        fundsRequest.start_date,
        fundsRequest.end_date,
        fundsRequest.request_reason
    ]);

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateFundsRequest ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <FundsRequestForm
                fundsRequest={fundsRequest}
                setFundsRequest={setFundsRequest}
                reasons={requestReasons}
                workers={workers}
                errorFields={errorFields}
            />
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} />
                </div>
            </div>
        </>
    );
};

export default FundsRequestCreate;
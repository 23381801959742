import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import CalendarExpensesContainer from './CalendarExpensesContainer';
import CalendarChecklistContainer from './CalendarChecklistContainer';

interface Props {
    eventId: number;
    eventTypeId?: number;
    calendarDate: string | Date;
    workerId: number;
    admin: boolean;
}

const ExpensesAndChecklists = ({ 
    eventId, 
    eventTypeId,
    calendarDate,
    workerId, 
    admin,
}: Props) => {

    return (
        <div>
            <Tabs  id="expenses-checklists-tabs" className="mb-3">
                <Tab eventKey="expenses-data" title="Gastos">
                    <CalendarExpensesContainer
                        eventId={eventId}
                        calendarDate={calendarDate}
                        admin={admin}
                        workerId={workerId}
                    />
                </Tab>
                
                <Tab eventKey="checklists-data" title="Encuestas">
                    <CalendarChecklistContainer
                        eventTypeId={eventTypeId}
                        calendarDate={calendarDate}
                    />
                </Tab>
            </Tabs>
        </div>
    )
}

export default ExpensesAndChecklists

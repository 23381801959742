import React from 'react';
import { FundsFilters } from '../../../../app/models/Fund';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: FundsFilters;
    setFilter: (filter: FundsFilters) => void;
    onSearch: () => void;
};

const FundsRequestFilter = ({ filter, setFilter, onSearch }: Props) => {

    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        
            <div className="row">
                <div className="row">
                    <div className="col-md-auto">
                        <div>
                            <label className="font-size-10 mb-1">Conductor</label>
                            <input
                                title="Conductor"
                                type="text"
                                id="carrier"
                                name="carrier"
                                className="form-control form-control-sm"
                                value={filter.carrier ?? ''}
                                onChange={(e) => handlerInputFilter(e)}
                            />
                        </div>
                    </div>
                    <div className="col-md-auto">
                        <div>
                            <label className="font-size-10 mb-1">Periodo desde</label>
                            <input
                                title="Fecha de emisión"
                                type="date"
                                id="start_date"
                                name="start_date"
                                className="form-control form-control-sm"
                                value={filter.start_date}
                                onChange={(e) => handlerInputFilter(e)}
                            />
                        </div>
                    </div>
                    <div className="col-md-auto">
                        <div>
                            <label className="font-size-10 mb-1">Peridod hasta</label>
                            <input
                                title="Fecha de emisión"
                                type="date"
                                id="end_date"
                                name="end_date"
                                className="form-control form-control-sm"
                                value={filter.end_date}
                                onChange={(e) => handlerInputFilter(e)}
                            />
                        </div>
                    </div>
                    <div className="col-md-auto">
                        <div>
                            <label className="font-size-10 mb-1">Motivo</label>
                            <select
                                id="request_reason"
                                name="request_reason"
                                className="form-control form-select-sm"
                                value={filter.request_reason ?? ''}
                                onChange={(e) => handlerSelectFilter(e)}
                            >
                                <option key={''} value="">
                                    Todos
                                </option>
                                <option key={'TRAVEL_EXPENSE'} value="TRAVEL_EXPENSE">
                                    Viaticos
                                </option>
                                <option key={'TOLL'} value="TOLL">
                                    Peaje
                                </option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-auto">
                        <div>
                            <label className="font-size-10 mb-1">Solicitado por</label>
                            <input
                                title="created_user"
                                type="text"
                                id="created_user"
                                name="created_user"
                                className="form-control form-control-sm"
                                value={filter.created_user ?? ''}
                                onChange={(e) => handlerInputFilter(e)}
                            />
                        </div>
                    </div>
                    <div className='col-md-auto'>
                        <div style={{marginTop: '22px'}}>
                            <ButtonSearchFilter callbackSearch={onSearch}/>
                        </div>
                    </div>
                </div>
            </div>
        
    );
};

export default FundsRequestFilter;

export interface FundsRequestDataForm {
    id?: number;
    start_date: Date | string;
    state?: boolean;
    worker?: Worker;
    worker_id?: number;
    worker_name?: string;
    end_date: Date | string;
    request_reason?: string;
    request_reason_id?: number;
    request_reason_name?: string;
    is_approved?: boolean;
    total?: number | string;
    created_user?: string;
    status?: string
    next_status_options?: FundRequestStatus[]
}

export interface FundRequestReason {
    value: number | string;
    label: number | string;
}

export interface FundRequestStatus {
    id?: number | string;
    name?: number | string;
}

export const defaultFundsRequestDataForm: FundsRequestDataForm = {
    start_date: '',
    end_date: '',
    worker_id: undefined
};

export interface FundsFilters{
    id?: number | null;
    name?: string | '';
    carrier?: string | null;
    is_active?: boolean | '';
    start_date?: string | '';
    end_date?: string | '';
    request_reason?: string | '';
    created_user?: string | '';
    
    page: number;
    per_page: number;
    sort: string;
    order: string;
}
